import React from "react"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import Tip from "../../../default/more/Tip/Tip"
import "./ShoppingList.scss"



function ShoppingList({ header, amount, footer, ingredients = [] }) {
    return (
        <BorderRadiusBox>
            <div className={"ShoppingList " + amount}>
                <div className=" p-2">
                    <div>
                        {header}
                    </div>
                    <hr />
                    {ingredients.map((i, k) =>
                        <IngredientItem ingredient={i} amount={amount} key={k} />
                    )}
                    <hr />
                    <div>
                        {footer}
                    </div>
                </div>
            </div>
        </BorderRadiusBox>
    )
}
export default ShoppingList


function IngredientItem({ ingredient, amount }) {
    let { Titel, id, Menge, Unit, isOptional, tip, adUrl, adText } = ingredient
    let renderAmount = getRenderAmount(Menge / 4 * amount, Unit)
    return (
        <a className={"IngredientItem " + amount + (isOptional && " isOptional")} href={adUrl} target="_blank">
            <span className="amount">{renderAmount}</span>

            <div className="inner">
                <span className="title">{Titel} {isOptional && <span className="optional">optional</span>}</span>
                <span className="adUrl">
                    <span>{adText}</span>
                </span>
            </div>



            {tip ? <Tip>{tip}</Tip> : null}
        </a >
    )
}

function getRenderAmount(amount, unit) {
    switch (unit) {
        case "Gramm":
            return transformUnit_gram(amount)

        case "Teelöffel":
            return transformUnit_tl(amount)

        case "Esslöffel":
            return transformUnit_el(amount)

        case "Milliliter (ml)":
            return transformUnit_ml(amount)

        default:
            return (amount || "") + " " + (unit || "")
    }
}

function transformUnit_tl(amount) {
    if (amount % 3 == 0) {
        return (amount / 3) + " El."
    }
    return (amount) + " Tl."
}

function transformUnit_el(amount) {
    return (amount) + " El."
}

function transformUnit_ml(amount) {
    if (amount >= 1000) {
        return amount / 1000 + " L"
    } else {
        return amount + " ml"
    }
}

function transformUnit_gram(amount) {
    if (amount >= 1000) {
        return amount / 1000 + " kg"
    } else {
        return amount + " g"
    }
}