import React from 'react';
import { render } from "react-dom";

import App from './App';
import './index.css';
import './styles/colors.scss';
import './styles/default.scss';
import './styles/fonts.scss';

const rootElement = document.getElementById("root");

render(<App />, rootElement);

// reportWebVitals();
