import React from "react"
import { Link } from "react-router-dom"
import TextPage from "../../components/default/pages/TextPage"
import { GetStrapiImageUrl } from "../../components/default/strapi/StrapiImage/StrapiImage"
import ErrorText from "../../components/loader/ErrorText"
import GhostLoader from "../../components/loader/GhostLoader"
import { useCategories } from "../../services/categories"
import "./CategoryListPage.scss"

function CategoryListPage({ }) {

    let { Data, Loading, Error } = useCategories()

    if (Loading) return <GhostLoader />
    if (Error) return <ErrorText>Fehler beim laden der Seite</ErrorText>
    if (Data) {

        console.log(Data.data)

        return (
            <TextPage title="Themen" className="Page CategoryListPage">
                <div className="row">
                    {Data.data.map((category, k) => <CategoryListItem {...category.attributes} key={k} />)}
                </div>
            </TextPage>
        )
    }
}
export default CategoryListPage

function CategoryListItem({ title, uri, specialists, books, titleImage }) {

    let bookCount = books.data.length
    let specialistsCount = specialists.data.length

    let imgSrc = GetStrapiImageUrl(titleImage)

    return (
        <div className="CategoryListItem col-12 col-md-6 col-lg-4 mb-4">
            <Link to={"/kategorie/" + uri} style={{ backgroundImage: `url(${imgSrc})` }}>

                <h2>{title}</h2>
            </Link>
        </div>
    )
}
