import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import BoxPage from "../../../components/default/pages/BoxPage/BoxPage"
import "./MagazineInfoPage.scss"

function MagazinInfoPage({ }) {
    return <div>
        <Helmet>
            <title>reformleben Magazin</title>
        </Helmet>
        <BoxPage className="MagazinPage" boxes={boxes} />
    </div>
}
export default MagazinInfoPage


const boxes = [
    {
        type: "WELCOME",
        payload: {
            title: "Magazin",
            subTitle: "Lesen Sie sich klug!",
            image: "/magazin/reMormLeben_offen_drMohr-basisprogramm.jpg",
        },
    },
    {
        type: "WelcomeTextBox",
        payload: {
            title: <>Unser Magazin mobil lesen!</>,
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ",
            image: "/magazin/reform-lkeben-magazin_responsive-website.png",
            suffix: <>
                <Link to="/magazin/news">
                    <button className="main m-0">Kostenfrei lesen <i className="fa-solid fa-chevron-right"></i></button>
                </Link>
            </>,
        },
    },
    {
        type: "ColoredBox",
        payload: {
            title: <>Unser <span>Magazin</span> zum Anfassen.</>,
            // image: "/magazin/girl_with_no_46.jpg",
            suffix: <>
                <Link to="/rezepte/">
                    <button className="mx-2 sub m-0">Händler finden <i className="fa-solid fa-chevron-right"></i></button>
                </Link>
                <Link to="/magazin/bestellen">
                    <button className="mx-2 main m-0">Jetzt bestellen <i className="fa-solid fa-chevron-right"></i></button>
                </Link>
            </>,
            items: [
                {
                    title: "100+ Manuell ausgewählte Rezepte",
                    text: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.",
                },
            ]
        },
    },
    // {
    //     type: "News",
    //     payload: {
    //         title: <>Neuigkeiten</>,
    //         text: "Test blabla.",
    //     },
    // },
]