import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import "./HeaderMenuMobile.scss"

function HeaderMenuMobile({ switchBreakpoint, links }) {

    const [Open, setOpen] = useState(false)


    function handlePress() {
        setOpen(!Open)
    }

    function closeMenu() {
        setOpen(false)
    }

    let icon = Open ? "times" : "bars"

    return (
        <div className="HeaderMenuMobile">
            <div className={`menu d-flex d-${switchBreakpoint}-none menu-mobile align-items-center`}>

                <button className="main">Jetzt beginnen</button>

                <div onClick={handlePress}>

                    {Open ?
                        <i className={`fa-solid fa-times p-3`} />
                        :
                        <i className={`fa-solid fa-bars p-3`} />

                    }
                </div>
            </div>


            <div className={"inner " + Open}>
                <div className="links">
                    {links.map((i, k) => <NavLink onClick={closeMenu} to={i.to}>
                        <span>{i.title}</span>
                    </NavLink>)}
                </div>
            </div>
        </div>
    )
}
export default HeaderMenuMobile