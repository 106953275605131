import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { BookList } from "../../../components/box/BookBox/BookBox";
import TitleImageBox from "../../../components/box/TitleImageBox/TitleImageBox";
import AdBanner from "../../../components/box/ad/AdBanner/AdBanner";
import AuthorBoxBig from "../../../components/box/news/AuthorBoxBig/AuthorBoxBig";
import ContentOverview from "../../../components/box/news/ContentOverview/ContentOverview";
import BorderRadiusBox from "../../../components/default/boxes/BorderRadiusBox/BorderRadiusBox";
import ShareButtons from "../../../components/default/buttons/ShareButtons/ShareButtons";
import HTML from "../../../components/default/more/HtmlRenderer/HtmlRenderer";
import GhostLoader_Article from "../../../components/loader/GhostLoader_Article";
import GhostLoader_Text from "../../../components/loader/GhostLoader_Text";
import { POST_BY_SLUG_ENDPOINT, POST_FROM_STRAPI_BY_SLUG_ENDPOINT } from "../../../const/Endpoints";
import { alenaxios } from "../../../functions/alenaxios";
import { useNewsArticleTooltips } from "../../../services/newsArticle/useNewsArticleTooltips";
import { useApi } from "../../../services/useApi";
import "./NewsArticlePage.scss";
const ChapterUriId = "kapitel-"

function NewsArticlePage({ }) {
    let { slug } = useParams();
    const strapiEndpoint = POST_FROM_STRAPI_BY_SLUG_ENDPOINT + slug;

    let { Data, Loading, Error } = useApi(strapiEndpoint)

    if (Loading) return <GhostLoader_Article />
    if (Error) return <p>Fehler beim laden.</p>
    if (Data) return (
        <NewsArticlePage_WordPressLoad strapiData={Data?.data[0]?.attributes || {}} slug={slug} />
    )
}

function NewsArticlePage_WordPressLoad({ strapiData, slug }) {

    // return <NewsArticlePage_Inner wordpressData={{}} strapiData={strapiData} slug={slug} />

    const wordpressEndpoint = POST_BY_SLUG_ENDPOINT + slug;
    let { Data, Loading, Error } = useApi(wordpressEndpoint, true, 0)

    // if(strapiData.html) {
    //         <NewsArticlePage_Inner wordpressData={} strapiData={strapiData} slug={slug} />
    // }
    if (Loading) return <GhostLoader_Article />
    if (Error) return <p>Fehler beim laden.</p>
    if (Data) {

        let wpData = Data[0]
        return (
            <NewsArticlePage_Inner wordpressData={wpData} strapiData={strapiData} slug={slug} />
        )
    }
}

function NewsArticlePage_Inner({ slug, wordpressData, strapiData }) {
    console.log("strapiData", strapiData)

    let { isAd, useClassicDesign, subTitle, html, tooltips, magazin, books, category, authors, image, } = strapiData //+tittle
    let strapiTitel = strapiData.title

    let { date, content, title, better_featured_image = {}, link, author, yoast_head } = wordpressData || {}
    let htmlContent = content?.rendered
    let imagesDetails = better_featured_image?.media_details || {}

    let source_url
    let file = imagesDetails?.file
    // let imagesSizes = imagesDetails.sizes || {}

    // let image = imagesSizes.large
    // let { file, width, height, source_url } = image || {}

    if (file) {
        source_url = "https://admin.reformleben.de/wp-content/uploads/" + file
    }

    link = window.location.href //Akteulle URL nutzen unabhängig von datenquelle 
    title = title?.rendered

    let publicText
    if (date) {
        date = new Date(date)
        publicText = `Veröffentlicht am ${date.toLocaleDateString()}` //um ${date.toLocaleTimeString("de-DE", "short")} Uhr
        publicText = publicText.replace("um 00:00:00 Uhr", "")
        publicText = publicText.replace(":00:00 Uhr", ":00 Uhr")
    } else {
        publicText = "Veröffentlichungsdatum lädt..."
    }

    const ldJson = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": title || '',
        "image": [
            better_featured_image?.media_details?.sizes?.full?.source_url || '',
            better_featured_image?.media_details?.sizes?.medium?.source_url || '',
            better_featured_image?.media_details?.sizes?.thumbnail?.source_url || '',
        ].filter(Boolean),
        "datePublished": date || '',
        "dateModified": yoast_head?.yoast_wpseo_article_modified || '',
        "author": author ? [{
            "@type": "Person",
            "url": `https://reformleben.de/author/${author.id}/`
        }] : []
    };
    // "name": author.name || '',



    return (
        <div
            className="Page NewsArticlePage"
        >
            <Helmet>
                {yoast_head}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>

            <TitleImageBox
                title={title}
                subTitle={strapiTitel}
                author={author}
                moreInfoText={publicText}
                source_url={source_url}
                category={category}
            />
            {/* <div className="titleImage" style={{ backgroundImage: `url(${source_url})` }} >
                <div className="inner">
                    <div className="container">
                        <div className="px-3 pt-2 pb-2">
                            <CategoryText category={strapiData.category} />
                            <h1 title="Titel" className="mb-1"><HTML>{title}</HTML></h1>
                            {subTitle && <span title="Untertitel">{subTitle}</span>}
                            <div className="mb-2"><AuthorBox authorID={author} /></div>
                            <span>{publicText}</span>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <img className="titleImage" alt={title} src={} /> */}


            <div className="container">
                <div className="row">

                    {/* Content  */}
                    <div className="col-12 col-md-8 order-md-1 p-2 ">
                        <article>
                            <div className="px-4">
                                <div className="mb-3 pb-2">
                                    <div className="d-flex flex-row justify-content-between align-items-top">
                                        {isAd ? <span className="isAdText">Anzeige</span> : null}
                                    </div>
                                </div>


                                {/*  Artikel   */}
                                <RenderedContent slug={slug} html={htmlContent} />
                                <small className="debug">Slug: {slug} | link: {link}</small>
                            </div>

                            <BorderRadiusBox className="d-none d-md-block">
                                <ShareButtons heading="Teilen Sie diesen Artikel!" url={link} title={title} text={`Schau dir mal bitte diesen Artikel an: ${link}`} />
                            </BorderRadiusBox>

                            <small style={{ opacity: 0.3 }}>{link}/qr/</small>

                        </article>
                    </div>


                    {/* Sidebar */}
                    <div className="col-12 col-md-4 order-md-2 p-2">

                        {/*  Inhaltsverzeichnis (Landet in der Mobilen version oben) */}
                        <BorderRadiusBox className={"d-none d-md-block"}>
                            <ContentOverview html={htmlContent} loading={false} />
                        </BorderRadiusBox>


                        <BorderRadiusBox>
                            <AdBanner className width={300} height={200} name="Wide Skyscraper" />
                        </BorderRadiusBox>



                        <BorderRadiusBox className={"w-100"}>
                            <AuthorBoxBig authorID={author} />
                        </BorderRadiusBox>


                        {books &&
                            <BorderRadiusBox className={"w-100 p-2"}>
                                <h3>Passende Bücher</h3>
                                <BookList books={books} />
                            </BorderRadiusBox>
                        }


                        <BorderRadiusBox>
                            <AdBanner className width={300} height={500} name="Wide Skyscraper" />
                        </BorderRadiusBox>



                        <BorderRadiusBox>
                            <ShareButtons heading="Teilen Sie diesen Artikel!" url={link} title={title} text={`Schau dir mal bitte diesen Artikel an: ${link}`} />
                        </BorderRadiusBox>

                        <BorderRadiusBox>
                            <AdBanner className width={420} height={600} name="Wide Skyscraper" />
                        </BorderRadiusBox>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default NewsArticlePage

const NO_TOOLTIPS_ERROR = "<p><b>Hinweis:</b> Begriffserklärungen zu diesem Artikel konnten nicht geladen werden. Bitte versuchen Sie diese Seite neu zu laden um alle Inhalte vollständig angezeigt zu bekommen.</p>"

function RenderedContent({ html = "", slug }) {
    let { Data, Loading, Error } = useNewsArticleTooltips(slug) //tooltips laden

    if (Loading) return <GhostLoader_Text words={200} />
    if (Error) return <HTML>{NO_TOOLTIPS_ERROR} {html}</HTML>
    if (Data && Array.isArray(Data)) {

        //*  Tooltips einsetzen
        Data.forEach(element => {
            let attributes = element?.attributes || {}
            let { word, tooltipContent, images } = attributes

            let word2 = word + "." //Damit zB beim Wort "Gesundheit", "Gesundheit." makrkirt wird
            word = word + " " //Damit zB beim Wort "Gesundheit" nicht auch Gesundheitssystem makrkirt wird

            let icon = "<i className='fa-solid fa-info-circle'></i>"
            html = html.replaceAll(word, `<span class"tt"><span className="ttt" >${tooltipContent}</span>${word}${icon}</span>`)
            html = html.replaceAll(word2, `<span className="tt"><span className="ttt">${tooltipContent}</span>${word}${icon}</span>`)
            //#endregion
            // html = html.replaceAll("Chromosomen.", "XXXXXX")
        });

        // Überschriften: id für Inhaltsverzeichnis setzten
        let count = 0
        while (html.includes("<h2>")) {
            html = html.replace("<h2>", `<h2 id=${ChapterUriId + count}>`)
            count++
        }

        //* AdBanner einsetzen
        let htmlParts = html.split('</p>');

        return htmlParts.map((part, k) => {
            let showAdBanner = (k + 1) % 3 == 0
            return <div>
                {showAdBanner &&
                    <AdBanner
                        key={k}
                        slot="5634092751"
                        format="fluid"
                        layoutKey="in-article"
                        height={300}
                        style={{ minHeight: 200 }}
                    />
                    // <AdBanner className width={420} height={600} name="Wide Skyscraper" />
                }
                <HTML>{part}</HTML>
            </div>
        })
    }
}


function useGetArticle_Old(slug = "zeitenwende", data) {
    let endpoint = POST_BY_SLUG_ENDPOINT + slug

    slug = slug.split("#")[0]

    const [Data, setData] = useState(null)
    const [Error, setError] = useState(null)
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        (
            async function () {
                if (data) {
                    setData(data)
                    setLoading(false)
                    setError(false)
                } else {
                    setLoading(true)
                    setError(false)
                    alenaxios({ url: endpoint })
                        .then(
                            res => {
                                setData(res.data[0])
                                return res.data
                            }
                        )
                        .catch(err => setError(err))
                        .finally(() => setLoading(false))
                }
            }
        )()
    }, [slug])
    return { Data, Error, Loading }
}