import { useEffect, useState } from "react"
import { REZEPT_ENDPOINT } from "../../const/Endpoints"
import { STRAPI_API_KEY } from "../../const/Keys"
// import { alenaxios } from "../../functions/alenaxios"
import axios from "axios"

export function useRecipe(slug) {
    const [Data, setData] = useState(null)
    const [Error, setError] = useState(null)
    const [Loading, setLoading] = useState(false)

    console.log("useRecipe")

    useEffect(() => {
        (
            async function () {
                let endpoint = REZEPT_ENDPOINT + slug + "&populate[0]=banner&populate[1]=ingredients&populate[2]=images&populate[3]=banner.image"
                console.log("endpoint", endpoint)
                setError(false)
                setLoading(true)

                let config = {
                    method: 'get',
                    url: endpoint,
                    headers: {
                        'Authorization': `Bearer ${STRAPI_API_KEY}`
                    }
                };

                axios(config)
                    .then(data => { setData(data || {}) })
                    .catch(() => setError(true))
                    .finally(() => setLoading(false))
            }
        )()
    }, [])

    return { Data, Loading, Error }
}