import React from "react"
import TextPage from "../../../components/default/pages/TextPage"
import "./IngredientPage.scss"

function IngredientPage({ }) {
    return (
        <TextPage
            title="Kurkuma"
            subTitle="Die faszinierende Wurzel mit jahrhundertealter Geschichte"
            className="IngredientPage"
            headerImage="https://strapi1.dashdesign.eu/uploads/Kurkuma_49c90a61b0.jpg"
        >

            <div className="row">
                <div className="col-7">

                    <h2>Allgemein</h2>
                    <p>
                        Kurkuma, auch bekannt als Gelbwurz oder indisches Safran, ist eine vielseitige und faszinierende Pflanze, deren Ursprünge in Südasien liegen. Seit Jahrhunderten wird sie nicht nur als würzige Zutat in der Küche verwendet, sondern auch in der traditionellen Medizin für ihre potenziellen heilenden Eigenschaften geschätzt. Die markante goldgelbe Farbe, die sie dem Essen verleiht, ist charakteristisch für ihre reiche Verbindung mit dem Wirkstoff Curcumin. In dieser Einleitung werden wir einen Blick auf die Herkunft, die kulturelle Bedeutung, die kulinarische Verwendung und die möglichen gesundheitlichen Vorteile dieser faszinierenden Wurzel werfen, die auch in der modernen Wissenschaft und Forschung zunehmend Beachtung findet. Tauchen wir ein in die Welt von Kurkuma und entdecken ihre vielfältigen Aspekte.
                    </p>
                </div>
                <div className="col-4">
                    <h2>Weitere Ingwergewächse:</h2>
                    <div className="">
                        Boesenbergia
                        Ingwer
                        Hedychium
                    </div>
                </div>
            </div>

            {/* <div><RezeptPageItem /></div> */}
        </TextPage>
    )
}
export default IngredientPage