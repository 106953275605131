import React from "react"
import { Link, NavLink } from "react-router-dom"
import { useCategories } from "../../../../services/categories"
import StaticImage from "../../../images/StaticImage"
import "./HeaderMenu.scss"
import HeaderMenuMobile from "./HeaderMenuMobile"

function HeaderMenu({ }) {

    let { Data, Loading, Error } = useCategories()
    if (Loading) return null
    if (Error) return <p>Lädt</p>

    if (Data) {


        const links = [
            {
                title: "Rezepte",
                to: "/rezepte",
                sub: null,
            },
            {
                title: "Magazin",
                to: "/magazin/info",
                sub: null,
            },
            {
                title: "Bücher",
                to: "/bücher",
                sub: null,
            },
            {
                title: "News",
                to: "/news",
                sub: null,
            },
            {
                title: "Themen",
                to: "/kategorie",
                sub: Data.data.map((i, k) => {
                    return { title: i.attributes.title, to: "/kategorie/" + i.attributes.uri };
                })

                // sub: [
                //     { title: "Natur", to: "/kategorie/natur/" },
                //     { title: "Fitness", to: "/kategorie/fitness" },
                //     { title: "Essen & Trinken", to: "/kategorie/essen-und-trinken" },
                //     { title: "Fitness", to: "/kategorie/fitness" },
                //     { title: "Fitness", to: "/kategorie/fitness" },
                //     { title: "Fitness", to: "/kategorie/fitness" },
                //     { title: "Fitness", to: "/kategorie/fitness" },
                // ],
            },
            // {
            //     title: "Über",
            //     to: "/about",
            //     sub: null,
            // },
            {
                title: "Basisprogramm",
                to: "/soon",
                sub: null,
            },

            // {
            //     title: "Shop",
            //     url: "/books",
            //     sub: null,
            // },
        ]

        let switchBreakpoint = "lg"

        return (
            <div className="HeaderMenu">

                <div className="container">
                    <div className="d-flex flex-row align-items-center justify-content-between">

                        <Link to="/">
                            <StaticImage
                                alt={"reformleben-logo"}
                                className="logo"
                                uri="/logo/logo_light.png"
                                uriDarkMode="/logo/logo_dark.png"
                            />
                        </Link>


                        <HeaderMenuMobile links={links} switchBreakpoint={switchBreakpoint} />


                        {/* Menü PC */}
                        <div className={`menu d-none d-${switchBreakpoint}-flex menu-desktop flex-row align-items-center right`}>

                            <div className="links d-flex me-2">
                                {links.map((i, k) =>
                                    <div className="link">
                                        <NavLink to={i.to} key={k} >{i.title}</NavLink>
                                        {
                                            i.sub && <div className="sub">
                                                {i.sub.map((ii, kk) => <Link to={ii.to} className="subItem">
                                                    {ii.title}
                                                </Link>

                                                )}
                                            </div>
                                        }
                                    </div>

                                )}
                            </div>

                            {/* <button className="sub">Mitglieder</button> */}
                            <a href="https://shop.reformleben.de">
                                <button className="sub">Shop</button>
                            </a>
                            <button className="main">Jetzt beginnen</button>
                        </div>


                    </div>
                </div>
            </div>
        )

    }
}

export default HeaderMenu