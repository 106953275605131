import React from "react";
import { Pie } from 'react-chartjs-2';
import "./RezeptNutritionalValues.scss";

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Link } from "react-router-dom";
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox";

ChartJS.register(ArcElement, Tooltip, Legend);


function RezeptNutritionalValues({ protein, fat, carbohydrates, kcal }) {

    const data = {
        labels: [`Protein ${protein}%`, `Fett ${fat}%`, `Kohlenhydrate ${carbohydrates}%`],
        datasets: [
            {
                label: '# of Votes',
                data: [protein, fat, carbohydrates],
                backgroundColor: [
                    '#20bf6b',
                    '#fed330',
                    '#333',
                ],
                borderWidth: 0,
            },
        ],
    };

    let empty = (!protein || !fat || !carbohydrates)


    return (
        <BorderRadiusBox className="RezeptNutritionalValues">
            <div className="p-4 pb-0">
                <div className="d-flex flex-row align-items-center justify-content-between pb-4">
                    <h3 className="m-0" >Nährstoffrelation</h3>
                    <Link to="/p/nährstoffrelation" target="_blank">Mehr dazu</Link>
                </div>
            </div>

            {!empty ?
                <div className="row">
                    <div className="col-7">
                        <div className="p-4 pt-0">
                            <div className="d-flex flex-row flex-wrap">
                                <Label percent={protein} title={"Protein"} color={"#20bf6b"} />
                                <Label percent={fat} title={"Fett"} color={"#fed330"} />
                                <Label percent={carbohydrates} title={"Kohlenhydrate"} color={"#333"} />
                            </div>
                        </div>
                    </div>

                    <div className="col-5">
                        <div className=" mb-3 p-1">
                            <div className="inner">


                                <Pie
                                    options={{}}
                                    data={data}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <p className="px-4">Keine Nährstoffrelation verfügbar.</p>
            }
        </BorderRadiusBox>
    )
}
export default RezeptNutritionalValues

function Label({ title, color, percent }) {
    return (
        <div className="Label">
            <div className="dot" style={{ backgroundColor: color }}></div>
            <span className="title"><b>{percent}%</b> {title}</span>
        </div>
    )
}