import React, { useState } from "react"
import "./FilterBar.scss"

function FilterBar({ filters, onFilter }) {

    const [Active, setActive] = useState(3)

    return (
        <div className="FilterBar">
            {filters.map((i, k) => <FilterBarItem {...i} isActive={k == Active} key={k} />)}
        </div>
    )
}
export default FilterBar

function FilterBarItem({ title, isActive, icon }) {
    return (
        <button
            className={"FilterBarItem " + (isActive && "isActive")}
        >
            {/* {icon} */}
            {title}
        </button>
    )
}