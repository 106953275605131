import React from "react"
import GhostLoader from "../../GhostLoader"
import GhostLoader_Text from "../../GhostLoader_Text"
import "./GhostLoader_AuthorBoxBig.scss"

function GhostLoader_AuthorBoxBig({ }) {
    return (
        <div className="AuthorBoxBig GhostLoader_AuthorBoxBig">

            <div>
                <GhostLoader
                    height={64}
                    width={64}
                    borderRadius={26}
                    className="ProfileImage"
                />
            </div>
            <div className="w-100">
                <span className="title pb-2">Autor</span>
                <GhostLoader_Text height={20} words="2" />
            </div>
            <div className="">
                <i className="fa-regular fa-chevron-right"></i>
            </div>

        </div>
    )
}
export default GhostLoader_AuthorBoxBig