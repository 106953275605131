import React from "react"
import { Link } from "react-router-dom"
import { useAuthor } from "../../../../services/author/useAuthorHook"
import StrapiImage from "../../../default/strapi/StrapiImage/StrapiImage"
import GhostLoader_AuthorBoxBig from "../../../loader/GhostLoader/GhostLoader_AuthorBoxBig/GhostLoader_AuthorBoxBig"
import "./AuthorBoxBig.scss"

function AuthorBoxBig({ authorID, showSlogan, prefix = "Autor" }) {
    let { Data, Error, Loading } = useAuthor(authorID);

    if (Loading || !authorID) return <GhostLoader_AuthorBoxBig />
    if (Error) return <div className="AuthorBox"><span className="name">KA.</span></div>
    if (Data) {
        let { fullName, profileImage, slug, slogan } = Data
        if (!fullName) return <small>Der Autor mit der ID '{authorID}' muss noch in Strapi angelegt werden.</small>
        return (
            <>
                <Link to={`/author/${authorID}/${fullName}`} className="AuthorBoxBig">
                    <div>
                        <StrapiImage className="ProfileImage" image={profileImage} />
                        {/* <ProfileImage fullName={fullName} /> */}
                    </div>
                    <div className="d-flex align-items-end w-100">
                        <div className="w-100">
                            {prefix && <span className="title">{prefix}</span>}
                            <span className="name">{fullName}</span>
                            {showSlogan && <span className="title">{slogan}</span>}

                        </div>
                        <div className="">
                            <i className="fa-regular fa-chevron-right"></i>
                        </div>
                    </div>
                </Link>
            </>
        )
    }
}
export default AuthorBoxBig