import React from "react"
import BorderRadiusBox from "../../../default/boxes/BorderRadiusBox/BorderRadiusBox"
import "./RezeptScore.scss"

function RezeptScore({ score, healthBecauseOf }) {

    let infos = scoreValues[score - 1]
    let { icon, title, } = infos || {}
    return (
        <BorderRadiusBox className="RezeptScore mb-4">
            <div>
                <div className="overview">
                    {icon}
                    <h3>{score}/10</h3>
                    <div>
                        <p>{title}</p>
                    </div>
                </div>

                {
                    healthBecauseOf ?

                        <div className="text">
                            <p className="m-0">{healthBecauseOf}</p>
                        </div>
                        : null}
            </div>
        </BorderRadiusBox>
    )
}
export default RezeptScore

const scoreValues = [
    {
        id: 1,
        title: "Mehr als extrem ungesund",
        icon: <i className="fa-sharp fa-solid fa-face-spiral-eyes"></i>,
    },
    {
        id: 2,
        title: "Extrem ungesund",
        icon: <i className="fa-solid fa-face-tongue-sweat"></i>,
    },
    {
        id: 3,
        title: "Sehr ungesund",
        icon: <i className="fa-solid fa-face-thermometer"></i>,
    },
    {
        id: 4,
        title: "Ungesund",
        icon: <i className="fa-solid fa-face-sad-tear"></i>,
    },
    {
        id: 5,
        title: "Ein bisschen ungesund",
        icon: <i className="fa-solid fa-face-relieved"></i>,
    },
    {
        id: 6,
        title: "Neutral",
        icon: <i className="fa-solid fa-face-raised-eyebrow"></i>,
    },
    {
        id: 7,
        title: "Ein bisschen gesund",
        icon: <i className="fa-solid fa-face-smile-relaxed"></i>,
    },
    {
        id: 8,
        title: "Gesund",
        icon: <i className="fa-solid fa-face-smile-tongue"></i>,
    },
    {
        id: 9,
        title: "Sehr gesund",
        icon: <i className="fa-solid fa-face-awesome"></i>,
    },
    {
        id: 10,
        title: "Extrem gesund",
        icon: <i className="fa-solid fa-face-melting"></i>,
    },
] 