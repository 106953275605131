import React from "react"
import BorderRadiusBox from "../../../components/default/boxes/BorderRadiusBox/BorderRadiusBox"
import StrapiImage from "../../../components/default/strapi/StrapiImage/StrapiImage"
import "./BannerList.scss"

function BannerList({ banner }) {
    return (
        <div className="BannerList">

            {banner.map((i, k) => <BannerItem {...i} key={k} />)}





        </div>
    )
}
export default BannerList



function BannerItem({ title, note, url, image }) {
    return (
        <BorderRadiusBox className="BannerItem p-3">

            <h3>{title}:</h3>
            <StrapiImage image={image} />




        </BorderRadiusBox>
    )
}