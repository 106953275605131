import React, { useState } from "react"
import AdBanner from "../../../components/box/ad/AdBanner/AdBanner"
import BuyPrintVersion from "../../../components/box/ad/BuyPrintBox/BuyPrintVersion"
import InstagramQrCode from "../../../components/box/ad/InstagramQrCode/InstagramQrCode"
import BasisProgrammBox from "../../../components/box/home/BasisProgrammBox"
import JoinNewsletterBox from "../../../components/box/news/JoinNewsletterBox/JoinNewsletterBox"
import NewsItemTile from "../../../components/box/news/NewsItemTile/NewsItemTile"
import NewsList from "../../../components/box/news/NewsList"
import SearchBoxWithText from "../../../components/box/news/SearchBoxWithText/SearchBoxWithText"
import TrendingBox from "../../../components/box/news/TrendingBox/TrendingBox"
import GhostLoader_Sidebar from "../../../components/loader/GhostLoader/GhostLoader_Sidebar/GhostLoader_Sidebar"
import { useApi } from "../../../services/useApi"
import "./MagazinePage.scss"

function MagazinePage({ }) {
    const [Categories, setCategories] = useState([])
    const [SearchText, setSearchText] = useState("")

    function handleArticleLoad(articleList) {

    }

    return (
        <div className="MagazinePage Page">
            <div className="">
                {/* <h1>Magazin</h1> */}
                <div className="row px-0 m-0 p-0">


                    <div className="col-lg-3 d-none d-lg-block p-2">


                        <SideBar id={1} />
                    </div>



                    <div className=" col-12 col-md-8 col-lg-6 p-2">
                        <div className="categories">


                            {/* <h1 className="text-center mb-4">Neue Beiträge:</h1> */}
                            <NewsList
                                searchText={SearchText}
                                // itemClassName="NewsItem col-12 p-1"
                                categories={Categories}
                                onCategorySwitch={(e) => setCategories(e.ids)}
                            />

                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 p-2">
                        {/* <CategorySelection className={"px-1 pt-1 pb-4"} onCategorySwitch={(e) => setCategories(e)} /> */}
                        <div className=" d-block d-lg-none">
                            <SideBar id={1} />
                        </div>
                        <SideBar id={2} />
                    </div>
                </div>
            </div>
        </div >
    )
}
export default MagazinePage

function SideBar({ id = 1 }) {
    const { Data, Loading, Error } = useApi(`/sidebars/${id}?populate=deep`, false, 0)
    if (Loading) return <div className=" sidebar"><GhostLoader_Sidebar /></div>
    if (Error) return <div>Serverfehler</div>
    if (Data) {
        let blocks = [...Data?.data?.attributes?.content]
        return (
            <div className="sidebar">
                <div>
                    {blocks.map((i, k) => <SideBarItem key={k} {...i} />)}

                </div>
            </div>
        )
    }
}

function SideBarItem(i) {
    let { __component, text, title } = i

    switch (__component) {
        case "blocks.search":
            return <SearchBoxWithText {...i} />
        case "blocks.trend-list":
            return <TrendingBox {...i} />
        case "blocks.print-version":
            return <BuyPrintVersion {...i} />
        case "blocks.basisprogramm":
            return <BasisProgrammBox {...i} />
        case "blocks.newsletter":
            return <JoinNewsletterBox {...i} />
        case "blocks.social-media":
            return <InstagramQrCode {...i} />

        case "blocks.featured":
            return <NewsItemTile {...i} />

        case "blocks.ad-banner":
            return <AdBanner
                slot={i.slot || "9410520388"}
                format={i.formart || "fluid"}
                layoutKey={i.layoutKey || "-60+cc-5-4s+n0"}
                style={{ minHeight: i.height || 350 }}
            />


        default:
            return <div><b>Fehler:</b> <p>Dieser Bereich kann nicht angezeigt werden. Bitte versuchen Sie die Seite neu zu laden.</p></div>
    }
}