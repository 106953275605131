import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import AuthorBoxBig from "../../../components/box/news/AuthorBoxBig/AuthorBoxBig";
import NewsList from "../../../components/box/news/NewsList";
import BorderRadiusBox from "../../../components/default/boxes/BorderRadiusBox/BorderRadiusBox";
import ShareButtons from "../../../components/default/buttons/ShareButtons/ShareButtons";
import HTML from "../../../components/default/more/HtmlRenderer/HtmlRenderer";
import TextPage from "../../../components/default/pages/TextPage";
import GhostLoader_Article from "../../../components/loader/GhostLoader_Article";
import { POST_BY_SLUG_ENDPOINT } from "../../../const/Endpoints";
import { alenaxios } from "../../../functions/alenaxios";
import "./NewsArticlePageQr.scss";

function NewsArticlePageQr({ }) {
    let location = useLocation()
    const { data } = location?.state || {}

    let { slug } = useParams();
    let { Data, Loading, Error } = useGetArticle(slug, data)

    const isAd = false //todo

    if (Loading) return <GhostLoader_Article />
    if (Error) return <p>Fehler beim laden.</p>
    if (Data) {
        let { id, date, link, content, title, better_featured_image, author } = Data
        let image = Data.better_featured_image?.media_details?.sizes?.large || {}
        let { file, width, height, source_url } = image || {}
        slug = Data.slug
        link = window.location.href.replace("/qr", "") //Akteulle URL nutzen unabhängig von datenquelle 
        title = title?.rendered
        let breadcrumpsProps = {
            path: [
                {
                    title: "Artikel",
                    uri: "/magazin/news/"
                },
                {
                    title: title,
                },
            ]
        }

        date = new Date(date)


        return (
            <TextPage
                metaTitle={title}
                className="Page NewsArticlePageQr"
                hideBreadcrumbs
            >
                <div className="row p-2">
                    {/* Author Box */}

                    <div className="mb-4">
                        <h3>Gescannter Artikel:</h3>
                        <Link to={"/p/" + slug} state={{ data: Data }} >
                            <BorderRadiusBox className="p-0 articleBox">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="img" style={{ backgroundImage: `url(${source_url})` }}>
                                        </div>
                                    </div>
                                    <div className="col-8 p-0">
                                        <div className=" p-0 textContent">
                                            <h1 className="m-0"><HTML>{title}</HTML></h1>
                                        </div>
                                    </div>
                                </div>
                            </BorderRadiusBox>
                        </Link>
                    </div>

                    <div className="mb-4">
                        <h3>Über den Autor:</h3>
                        <BorderRadiusBox >
                            <AuthorBoxBig authorID={author} prefix={false} showSlogan />
                        </BorderRadiusBox>
                    </div>

                    <div className="mb-4">
                        <h3>Artikel teilen:</h3>
                        <BorderRadiusBox>
                            <ShareButtons heading={false} url={link} title={title} text={`Schau dir mal bitte diesen Artikel an: ${link}`} />
                        </BorderRadiusBox>
                    </div>

                    <div className="mb-5">
                        <h3>Weitere Artikel</h3>
                        {/* So Filtern, das nicht der slebe Artikel nochmal Vorgeschlagen wird */}
                        <NewsList hideArticle={slug} />
                    </div>
                </div>
            </TextPage>
        )
    }
}
export default NewsArticlePageQr



function useGetArticle(slug = "zeitenwende", data) {
    let endpoint = POST_BY_SLUG_ENDPOINT + slug

    slug = slug.split("#")[0]

    const [Data, setData] = useState(null)
    const [Error, setError] = useState(null)
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        (
            async function () {
                console.log("data alo lade ", data)
                if (data) {
                    setData(data)
                    setLoading(false)
                    setError(false)
                } else {
                    console.log("Kein date hol dir")

                    setLoading(true)
                    setError(false)
                    alenaxios({ url: endpoint })
                        .then(
                            res => {
                                setData(res.data[0])
                                return res.data
                            }
                        )
                        .catch(err => setError(err))
                        .finally(() => setLoading(false))
                }
            }
        )()
    }, [slug])
    return { Data, Error, Loading }
}