import { useApi } from "./useApi";

export function useCategories(uri) {

    let endpoint = "/categories?populate[0]=titleImage&populate[1]=specialists&populate[2]=specialists.profileImage&populate[3]=books&populate[4]=books.coverImage"

    if (uri) {
        endpoint += "&filters[uri][$eq]=" + uri
    }

    return useApi(endpoint);
}

