import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BookList } from "../../components/box/BookBox/BookBox";
import TitleImageBox from "../../components/box/TitleImageBox/TitleImageBox";
import AuthorBoxBig from "../../components/box/news/AuthorBoxBig/AuthorBoxBig";
import NewsList from "../../components/box/news/NewsList";
import BorderRadiusBox from "../../components/default/boxes/BorderRadiusBox/BorderRadiusBox";
import { GetStrapiImageUrl } from "../../components/default/strapi/StrapiImage/StrapiImage";
import ErrorText from "../../components/loader/ErrorText";
import GhostLoader from "../../components/loader/GhostLoader";
import { useCategories } from "../../services/categories";
import { Page404 } from "../more/PageRedirectOr404/PageRedirectOr404";
import "./CategoryPage.scss";

function CategoryPage() {
    let { uri } = useParams();
    const [URI, setURI] = useState(uri);

    useEffect(() => {
        setURI(uri);
    }, [uri]);
    return <CategoryPageInner uri={URI} />;
}

function CategoryPageInner({ data, uri }) {

    let { Data, Loading, Error } = useCategories(uri)
    if (Loading) return <GhostLoader />
    if (Error) return <ErrorText />
    if (Data) {
        let { title, titleImage, icon, specialists, books, text, introduction, uri } = Data.data[0].attributes

        return (
            <div className="Page CategoryPage">
                <TitleImageBox source_url={GetStrapiImageUrl(titleImage, "large")} />

                <div className="container px-4">

                    <div className="row mb-5">

                        <div className="col-12 col-md-7">
                            <div className="mb-5 pb-5">

                                {/* <i className={`fa-solid titleIcon fa-` + icon} />
                                <hr /> */}
                                <h1>{title}</h1>

                                {/* //Sonderseite bei essen & Trinken */}
                                {uri == "essen-und-trinken" && <div className="d-flex">
                                    <button className="">Zu unseren Rezepten <i className="fa-solid fa-chevron-right"></i></button>
                                </div>}

                                <p><b>{introduction || `Zeitnah finden Sie hier alles zum Thema ${title}.`}</b></p>

                                {/* <h2>Mehr zu dem Thema</h2> */}
                                <small>{text}</small>
                            </div>

                            {/* Beiträge */}
                            <div>
                                <h2 className="">Beiträge</h2>
                                <NewsList searchText={title} hideSearchText />
                            </div>
                        </div>

                        <div className="col-12 col-md-4 offset-md-1">
                            <div>
                                <BorderRadiusBox className="p-3">
                                    <h2>Spezialisten</h2>
                                    {specialists?.data?.map((i, k) =>
                                        <AuthorBoxBig authorID={i.attributes?.wordpressID} key={k} />
                                    )}
                                </BorderRadiusBox>

                                <BorderRadiusBox className="p-3">
                                    <h2>Bücher</h2>
                                    <BookList books={books} />
                                </BorderRadiusBox>
                                {/* <AdBanner /> */}
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        )
    } else {
        return <Page404 />
    }
}
export default CategoryPage
