import React from "react";
import "./HomePage.scss";

import { Link } from "react-router-dom";
import BoxPage from "../../components/default/pages/BoxPage/BoxPage";

function HomePage({ }) {
    return <BoxPage className="HomePage" boxes={boxes} />
}
export default HomePage

const boxes = [
    {
        type: "WELCOME",
        payload: {
            title: "Meine Gesundheit 3.0",
            subTitle: <>Ab jetzt gestalten Sie Ihre Gesundheit selbst!<br /><small></small></>,
            image: "/homepage/paar-geht-joggen.jpg",
            height: 800,
            suffix: <>
                {/* <Link to="/basisprogramm">
                    <button className="main">Basisprogramm</button>
                </Link> */}


                {/* <button className="ms-0 me-1 mb-1"> <small>Jetzt beginnen</small> </button> */}
                <a target={"_blank"} href="https://zcmp.eu/ycJ">
                    <button className="ms-0 me-1 mb-1"> <small>Jetzt beginnen</small> </button>
                </a>
            </>,
        },
    },
    {
        type: "WelcomeTextBox",
        payload: {
            title: <>Das Basisprogramm</>,
            subTitle: <>von Dr. med. Klaus Mohr</>,
            text: <p><h3>Zurück zu nachhaltiger Gesundheit</h3>Ursachen statt Symptome bekämpfen - Passen Sie Ihren Lebensstil in 6 Bereichen an, um den Boden für nachhaltige Gesundheit, Langlebigkeit, optimale Leistungsfähigkeit und Selbstheilungskraft zu legen.<br /><br />Lernen Sie die Grundlagen nachhaltiger Gesundheit kennen und starten Sie Ihr persönliches Gesundheits-Projekt!</p>,
            image: {
                uri: "/basisprogramm/basisprogramm_dr_mohr-uebersicht-kreis.png",
                alt: "Basisprogramm Kreis: Sonne, Regenaration, Low carb, Darm, Immunsystem, Proteine"
            },
            suffix: <>
                <Link to="/basisprogramm">
                    <button className="main m-0">Mehr erfahren <i className="fa-solid fa-chevron-right"></i></button>
                </Link>
            </>,
        },
    },
    {
        type: "ColoredBox",
        payload: {
            title: <><span style={{ color: "var(--sub)" }}>Rezepte</span></>,
            subTitle: "Leckere, gesunde Gerichte einfach selbst zubereiten",
            image: {
                uri: "/rezepte/frau-die-rezept-kocht-mit-reform-assistent.png",
                alt: "Frau kocht gesundes Essen"
            },
            suffix: <>
                <Link to="/rezepte/">
                    <button className="main m-0">Zu den Rezepten <i className="fa-solid fa-chevron-right"></i></button>
                </Link>
            </>,
            items: [
                {
                    title: "",
                    text: "In diesem Bereich finden Sie zahlreiche Rezepte, die unser Konzept gesunder Ernährung widerspiegeln. Suchen Sie sich Ihr Lieblings-Rezept und lassen Sie sich von unserem Assistenten durch die Schritt für Schritt-Anleitung führen. So gelingt jedes Gericht auf Anhieb.",
                },
            ]
        },
    },
    {
        type: "WelcomeTextBox",
        payload: {
            title: <>Unser Magazin</>,
            // subTitle: <>Hier eine Headline</>,
            text: <></>,
            image: {
                uri: "/homepage/magazin_seite_gesundheittipps.png",
                alt: "Offenes Magazin mit reformleben Instagram-Seite auf einem iPhone und Gesundheitttipps"
            },
            suffix: <>
                <h3>Das Online-Magazin</h3>
                <p>Lassen Sie sich in unserem <Link to="/magazin">Magazin-Bereich</Link> von aktuellen Meldungen und Beiträgen inspirieren.</p>
                <div className="mb-5 pb-3">
                    <Link to="/magazin">
                        <button className=" m-0">Online lesen <i className="fa-solid fa-chevron-right"></i></button>
                    </Link>
                </div>

                <div className="mb-5">
                    <h3>Die Print-Ausgabe</h3>
                    <p>
                        Die aktuelle Print-Ausgabe von reformleben liegt in allen teilnehmenden Reformhäusern, Apotheken und Bio-Läden mit Reformwaren-Sortiment in Deutschland, Österreich und der Schweiz für Sie zur Abholung bereit.
                    </p>

                    <a target={"_blank"} href="https://reformhaus.de/pages/store-locator">
                        <button className="m-0 main">Reformhaus finden <i className="fa-solid fa-chevron-right"></i></button>
                    </a>
                </div>
            </>,
        },
    },
    // {
    //     type: "News",
    //     payload: {
    //         title: <>Neuigkeiten</>,
    //     },
    // },
]