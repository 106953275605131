// searchPost.js



const Screens = [

    {
        uri: "/",
        title: "Startseite",
        searchTags: "home index",
        showInSearch: true,
        icon: "home",
    },

    {
        uri: "/impressum",
        title: "Impressum",
        searchTags: "",
        showInSearch: true,
        icon: "home",
    },
    {
        uri: "/about",
        title: "Über uns",
        searchTags: "reformleben",
        showInSearch: true,
        icon: "info-circle",
    },

    {
        uri: "/serverstatus",
        title: "Serverstatus",
        searchTags: "serverstatus ausfall offline ",
        showInSearch: true,
        icon: "",
    },

    {
        uri: "/bücher",
        title: "Buchempfelungen",
        searchTags: "Bücher",
        showInSearch: true,
        icon: "book-open",
    },

    {
        uri: "/rezepte",
        title: "Rezepte",
        desc: "Finden Sie tolle Rezepte.",
        searchTags: "kochen backen vegan essen",
        showInSearch: true,
        icon: "chef",
    },

    {
        uri: "/magazin",
        title: "Infos zum Magazin",
        searchTags: "heft ausgabe print pdf",
        showInSearch: true,
        icon: "file-pdf",
    },

    {
        url: "https://shop.reformleben.de",
        title: "Magazin bestellen",
        searchTags: "heft ausgabe print pdf kaufen liefern",
        showInSearch: true,
        icon: "file-pdf",
    },
]



export default async function searchPage(searchText) {
    searchText = searchText.toLowerCase()

    let hits = Screens.filter((screen => checkPage(screen, searchText)))
    return hits || []
}

//Prüf Algo. für jede einzelne Seite
function checkPage(screen, searchText) {
    if (screen.showInSearch) { //Pürfen ob es eine Seite mit titel ist
        if (
            typeof screen.title == "string" && screen.title.toLowerCase().includes(searchText)
            ||
            typeof screen.searchTags == "string" && screen.searchTags.includes(searchText)
        ) {
            return true
        } else {
            return false
        }
    }
}