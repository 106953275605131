import React from "react"
import { Link } from "react-router-dom"
import "./PageSearch.scss"

function PageSearch({ uri, title, desc, searchTags, showInSearch }) {
    // if (!showInSearch) return null


    return (
        <Link to={uri} className="col-12 col-md-6 p-1">
            <div className="PageSearch">
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>
        </Link>
    )
}
export default PageSearch