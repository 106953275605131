import React from "react"
import { Link } from "react-router-dom"
import "./CookButton.scss"

function CookButton({ cookUrl }) {


    return (
        <Link to={cookUrl} aria-label="Rezept im Kocheprogram öffnen" >
            <button className="CookButton main d-print-none">
                {/* <i className="fa-solid fa-hat-chef"></i> */}
                <div className="d-flex flex-column">
                    <div>
                        <i className="fa-solid fa-hat-chef"></i> Jetzt kochen
                    </div>
                    {/* <small>Bald verfügbar.</small> */}
                </div>
            </button>
        </Link>
    )
}
export default CookButton           