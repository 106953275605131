import React from "react"
import NewsList from "../../../components/box/news/NewsList"
import TextPage from "../../../components/default/pages/TextPage"
import "./NewsPage.scss"

function NewsPage({ newsItem }) {



    return (
        <TextPage
            title="Artikel"
            headerImage="/magazin/reMormLeben_offen_drMohr-basisprogramm.jpg"
            className="NewsPage"
            useBottomTitle
            enableSuperBanner
        >

            <NewsList />

        </TextPage>
    )
}
export default NewsPage