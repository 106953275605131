import NewsItem from "../../components/box/news/NewsItem";
import PageSearch from "../../components/search/PageSearch/PageSearch";
import searchArticle from "./searchArticle";
import searchPage from "./searchPage";

export default async function GlobalSearch(searchText) {

    const article = await searchArticle(searchText);
    const pages = await searchPage(searchText);

    const results = {
        hits: article.length + pages.length,
        categories: ContentTypeCategories.map(category => ({
            ...category,
            items: category.title === "Seiten" ? pages : category.title === "Artikel" ? article : category.items
        }))
    };

    return results;

}


export const ContentTypeCategories = [
    {
        icon: "file",
        title: "Seiten",
        items: [],
        render: PageSearch,
    },
    {
        icon: "newspaper",
        title: "Rezepte",
        items: [],
        render: (i) => <p>{JSON.stringify(i)}</p>,
    },
    {
        icon: "user",
        title: "Autoren",
        items: [],
        render: (i) => <p>{JSON.stringify(i)}</p>,
    },
    {
        icon: "book",
        title: "Bücher",
        items: [],
        render: (i) => <p>{JSON.stringify(i)}</p>,
    },
    {
        icon: "book-open",
        title: "Ausgaben",
        items: [],
        render: (i) => <p>{JSON.stringify(i)}</p>,
    },
    {
        icon: "newspaper",
        title: "Events",
        items: [],
        render: (i) => <p>{JSON.stringify(i)}</p>,
    },
    {
        icon: "newspaper",
        title: "Artikel",
        items: [],
        render: (i) => <div><NewsItem {...i} /></div>,
    },
];

