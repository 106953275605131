import { Helmet } from "react-helmet"
import AdBanner from "../../box/ad/AdBanner/AdBanner"
import SearchInput from "../inputs/SearchInput/SearchInput"
import Breadcrumps from "../more/Breadcrumps/Breadcrumps"
import "./TextPage.scss"

function TextPage({
    title,
    className,
    metaTitle,
    metaDescription,
    headerImage = "",
    subTitle,
    children,
    metaBonus,
    useBottomTitle = false,
    hideBreadcrumbs = false,
    enableSuperBanner = false,
    headerContent,
    onSearch,
    breadcrumpsProps,
    searchText,
    noContainer = false
}) {

    let img = headerImage

    if (!headerImage.includes("https://")) {
        img = process.env.PUBLIC_URL + "/assets/image" + headerImage
    }


    //breadcrumpsProps erstellen, wenn es keine gibt
    if (!breadcrumpsProps) {
        breadcrumpsProps = {
            path: [
                {
                    title: title || "Text-Seite",
                    to: "/"
                }
            ]
        }
    }

    return (
        <div className={"Page TextPage " + className}>
            <Helmet>
                <title>{metaTitle || title}</title>
                <meta name="description" content={metaDescription || metaTitle || title} />
                {metaBonus}
                <meta property="og:title" content={metaTitle || title} />
                <meta property="og:image" content={headerImage} />
                <meta property="twitter:image" content={headerImage} />
            </Helmet>

            {enableSuperBanner &&
                <div className="SuperBanner d-none d-xxl-block">
                    <AdBanner width={160} height={600} />
                    <AdBanner width={160} height={600} />
                </div>
            }

            <div className="header">
                {headerContent ?
                    <div className="headerContent">
                        {headerContent}
                    </div>
                    : null}

                {headerImage ?
                    <div className="headerImage" style={{ backgroundImage: `url(${img})` }}>
                        <div className="inner">
                            <div className="container">
                                {!useBottomTitle && <h1>{title}</h1>}
                                {subTitle ? <h2 className="subTitle text-center">{subTitle}</h2> : null}
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className="pt-5">
                            {!useBottomTitle && <h1>{title}</h1>}

                            {subTitle ? <h2 className="subTitle">{subTitle}</h2> : null}
                        </div>
                    </div>
                }
            </div>


            <div className={!noContainer && "container"}>
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
                    <div>
                        {!hideBreadcrumbs ?
                            <Breadcrumps {...breadcrumpsProps} />
                            : null}
                        {useBottomTitle ? <h1>{title}</h1> : null}
                    </div>

                    {/* Suchleiste */}
                    <div>
                        {onSearch ?
                            <div className="mb-5 mb-md-0">
                                <SearchInput onSearch={onSearch} searchText={searchText} isOpen />
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div>
                    {children}
                </div>
            </div>

        </div>
    )
}
export default TextPage