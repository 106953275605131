import React from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../../services/useApi";
import "./PageSoon.scss";

function PageSoon({ }) {
    let uri = window.location.pathname.replace(/^\/+/g, ''); //encodeURI(??
    let { Data, Loading, Error } = useApi("/redirect-links?filters[uri][$eq]=" + uri, false, 0)




    return (
        <div className="Page Page404">
            <div style={{ maxWidth: 900 }} className="container">
                <span>
                    <i className="fa-solid fa-face-rolling-eyes"></i>
                </span>
                <h1>Wartungsarbeiten</h1>
                <span style={{ opacity: 0.5 }}>Bitte entschuldigen Sie, diese Seite wird aktuell überarbeitet.</span>
                <hr></hr>
                <p>Wir verbessern aktuell diesen Bereich unserer Website. <br />Bitte schauen Sie morgen erneut vorbei!</p>
                <hr></hr>
                <Link to="/">
                    <button>Zur Startseite</button>
                </Link>
            </div>
        </div>
    )

}
export default PageSoon
