export const STRAPI = "https://strapi1.dashdesign.eu"
export const STRAPI_API = STRAPI + "/api"
export const STRAPI_UPLOADS = STRAPI + "/uploads"
export const DEFAULT_DOMAIN = "https://admin.reformleben.de"
export const DEFAULT_PATH = "/wp-json/wp/v2"

export const DEFAULT_ENDPOINT = DEFAULT_DOMAIN + DEFAULT_PATH

// Paths 
export const AUTHOR_ENDPOINT = DEFAULT_ENDPOINT + "/users/" // + ID
export const POST_BY_SLUG_ENDPOINT = DEFAULT_ENDPOINT + "/posts?slug=" // + SLUG
export const POST_FROM_STRAPI_BY_SLUG_ENDPOINT = '/articles?populate[0]=tooptips&populate[1]=magazin&populate[2]=books&populate[3]=category&populate[4]=authors&populate[5]=magazin.cover&populate[6]=author.profileImage&populate[7]=author.bannerImage&populate[8]=books.titleImage&filters[slug][$eq]='// + SLUG
export const POST_ENDPOINT = DEFAULT_ENDPOINT + "/posts" // (+ ID)
export const POST_TOOLTIPS_ENDPOINT = STRAPI_API + "/tooltips?populate=*&filters[articleUrl][$eq]="// + slug
export const POST_SEARCH_ENDPOINT = DEFAULT_ENDPOINT + "/search?type=post&title=" // +test

//Rezepte
export const REZEPTE_ENDPOINT = STRAPI_API + "/recipes/?populate[0]=banner&populate[1]=ingredients&populate[2]=images&populate[3]=banner.image&populate[4]=nutritionalValues"
export const REZEPT_ENDPOINT = STRAPI_API + "/recipes/?filters[slug][$eq]=" // + slug
export const REZEPT_SEARCH_ENDPOINT = STRAPI_API + "/recipes/?populate[0]=banner&populate[1]=ingredients&populate[2]=images&populate[3]=banner.image&populate[4]=nutritionalValues&filters[title][$contains]=" //+ searchStr

//Newspapers
export const JOIN_NEWSPAPER_ENDPOINT = "https://zoho.com/" // + email