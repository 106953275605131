import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import RezeptePageInner from "../../../../container/Rezepte/RezeptePage/RezeptePageInner"
import GeneralSearchPage from "../../../../container/search/GeneralSearchPage/GeneralSearchPage"
import PostSearchPage from "../../../../container/search/PostSearchPage/PostSearchPage"
import GlobalSearch, { ContentTypeCategories } from "../../../../services/search/GlobalSearch"
import FilterBar from "../../../box/FIlterBar/FIlterBar"
import "./Search.scss"

const types = [
    {
        title: "Artikel",
        component: <PostSearchPage />
    },
    {
        title: "Rezepte",
        component: <RezeptePageInner />
    },
    // {
    //     title: "Autoren",
    // },
    {
        title: "Allgemeine Seiten",
        component: <GeneralSearchPage />
    },
    // {
    //     title: "Reformhäuser",
    // },
]
function Search(props) {
    if (!props.searchText || props.searchText == "") return null
    return <SearchInner {...props} />
}

function SearchInner({ isOpen = false, onClose = () => null, onSearch = () => { }, searchText = "", }) {

    const location = useLocation()

    const [Type, setType] = useState()
    const [Results, setResults] = useState({})
    const [SearchStarted, setSearchStarted] = useState(false)
    const [Loading, setLoading] = useState(true)

    const handleSearchEvent = (e) => onSearch(e?.target?.value)
    const handleClose = () => {
        onClose()
    }

    async function handleSearchStart(st) {
        try {
            setLoading(true)
            const resultsPromise = GlobalSearch(st);
            const results = await resultsPromise;
            setResults(results);
            setLoading(false)
            setSearchStarted(true);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        handleSearchStart(searchText)
        handleClose()
    }, [location])

    return (
        <div className={"Search isOpen"}>
            <div className="inner">


                <div className="d-flex flex-row justify-content-between align-items-start header">
                    {/* Suche nach & "Nur im Bereich XY" Anzeige */}
                    <div>
                        <Link to="#" onClick={handleClose}><i className="fa-regular fa-chevron-left" /> Zurück</Link>
                        <div className="mb-3">
                            <h3 className="SearchInfoText m-0">Suche nach <input
                                onChange={handleSearchEvent}
                                value={searchText}
                                placeholder="Suchbegriff eingeben"
                            /></h3>
                            {Type ? <span className="SearchInfoSubText">Nur im Bereich: {Type}</span> : null}
                        </div>
                    </div>
                    {/* Close button */}
                    <button onClick={handleClose} className="Close clear" aria-label="Suchen">
                        <i className="fa-solid fa-xmark-circle"></i>
                    </button>
                </div>


                <FilterBar filters={ContentTypeCategories} />
                {/* {ContentTypeCategories.map((i, k) => <button key={k}>{i.title}</button>)}
                </FilterBar> */}


                {Loading && <p>Suche lädt...</p>}

                {/* <h2>Entschuldigen Sie bitte, die Suche wird aktuell überarbeitet. <br />Bitte versuchen Sie es morgen erneut.</h2> */}


                <div>
                    {Results instanceof Promise ? (
                        <p>Lädt...</p>
                    ) : (
                        <div>
                            <SearchHits {...Results} />
                        </div>
                    )}
                </div>


            </div>
        </div>
    )
}
export default Search

function SearchHits(props) {

    let { hits, categories = [] } = props

    console.log(props)

    return (
        <div className="SearchHits">
            <h1>{hits} Treffer</h1>
            <hr />
            <div>
                {categories.map((i, k) =>
                    <SearchHitCategory {...i} key={k} />
                )}
            </div>
        </div>
    )
}

function SearchHitCategory(category) {
    let hits = category.items.length

    if (!hits || hits == 0) {
        return null
    } else {
        return (
            <div className="SearchHitCategory" >
                <h2>
                    <i className={`fa-solid fa-${category.icon}`} />
                    {" " + category.title}
                    {/* ({hits}) */}
                </h2>
                <div>
                    <div className="row">
                        {category.items.map((i, k) => category.render(i)
                        )}
                    </div>

                    <div>

                    </div>
                </div>
            </div>
        )
    }
}



function TypeSelector({ types: [], onTypeChange }) {

    const [Selection, setSelection] = useState()

    function handleSelect(s) {
        let newVal = s

        //Wenn man die Auswahl zurück nimmt
        if (Selection == s) {
            newVal = null
        }

        onTypeChange(newVal)
        setSelection(newVal)
    }


    return <div className="TypeSelector">
        {types.map((i, k) =>
            <div
                key={k}
                className={i.title == Selection ? "Type isActive" : "Type"}
                onClick={() => handleSelect(i.title)}
            >
                <span>{i.title}</span>
            </div>
        )}
    </div>
}