import React from "react"
import HTML from "../../default/more/HtmlRenderer/HtmlRenderer"
import CategoryText from "../categories/CategoryText/CategoryText"
import AuthorBox from "../news/AuthorBox"
import "./TitleImageBox.scss"

function TitleImageBox({ title, subTitle, author, moreInfoText, category, source_url }) {


    console.log("source_url", source_url)

    if (!source_url) {
        return (
            <div className="container">
                <div className="px-3 pt-5 pb-2">
                    {category && <CategoryText category={category} />}
                    {title && <h1 title="Titel" className="mb-1"><HTML>{title}</HTML></h1>}
                    {subTitle && <span title="Untertitel">{subTitle}</span>}
                    {author && <div className="mb-2"><AuthorBox authorID={author} /></div>}
                    {moreInfoText && <span>{moreInfoText}</span>}
                </div>
            </div >
        )
    }
    return (
        <div className="TitleImageBox" style={{ backgroundImage: `url(${source_url})` }} >
            <div className="inner">
                <div className="container">
                    <div className="px-3 pt-2 pb-2">
                        {category && <CategoryText category={category} />}
                        {title && <h1 title="Titel" className="mb-1"><HTML>{title}</HTML></h1>}
                        {subTitle && <span title="Untertitel">{subTitle}</span>}
                        {author && <div className="mb-2"><AuthorBox authorID={author} /></div>}
                        {moreInfoText && <span>{moreInfoText}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TitleImageBox